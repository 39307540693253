import React from "react"
import { graphql, Link } from "gatsby"

import { CaseStudySummary, Icon, Layout, PageSection, SEO } from "../components"

const CaseStudyTemplate = ({ data }) => {
  const currentCaseStudy = data.markdownRemark
  const { frontmatter, html } = currentCaseStudy
  currentCaseStudy.slug = null

  return (
    <Layout>
      <>
        <SEO title={`${frontmatter.title} :: Case Study`} />
        <PageSection
          id="case-study__header"
          theme="mid"
          title="Case study"
          subtitle={frontmatter.title}
        ></PageSection>
        <PageSection id="case-study__content" variant="left">
          <div className="case-study  case-study--full">
            <CaseStudySummary caseStudy={currentCaseStudy.frontmatter} />

            <div className="case-study__body  content  content--compact">
              <div
                className="case-study__additional"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <p>
                <small>
                  <Icon name="arrow-left" fill="#B7B7B7" />{" "}
                  <Link to="/work">All case studies</Link>
                </small>
              </p>
            </div>
          </div>
        </PageSection>
      </>
    </Layout>
  )
}

export default CaseStudyTemplate

export const pageQuery = graphql`
  query($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      frontmatter {
        title
        dates
        role
        technologies
        location
        website
      }
      html
    }
  }
`
